//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardBody, BFormCheckboxGroup, BFormCheckbox, BSkeleton, BSkeletonWrapper } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';

var formatter = new Intl.NumberFormat({
  style: 'currency',
  currency: 'UYU',
  currencyDisplay: "code",
  minimumFractionDigits: 0,
});

export default {
  name: "CourseFloatingCard",
  components: { BCard, BCardBody, BFormCheckboxGroup, BFormCheckbox, BSkeleton, BSkeletonWrapper, Icon },
  props: {
    img: String,
    imgAlt: String,
    name: String,
    shortName: String,
    pricePesos: Number,
    priceUSD: Number,
    dateCourse: String,
    schedule: Array,
    language: String,
    level: String,
    modality: String,
    places: Boolean,
    duration: String,
    category: String,
    graduationCertificate: Boolean,
    graduationCertificateExpires: Boolean,
    requiresPreviousKnowledge: Boolean,
    meeting: String,
    courseTimes: Number,
  },
  data() {
    return {
      paymentMethod: ["paypal"],
      buttonURL: "/es/concretar-compra",
      promo: "disable",
      priceMercadoPago: 0,
    }
  },
  computed: {
    loading() {
      return !(this.pricePesosString && this.priceUSD);
    },
    pricePesosString() {
      return this.pricePesos ? formatter.format(this.pricePesos) : "";
    },
  },
  methods: {
    onBtnClick(e) {
      if (this.promo === 'active') {
        e.preventDefault();
        this.triggerModal(e);
      } else {
        this.$emit("btnClick");
        let payment = document.querySelector('input[name="checkboxes-payment-method"][type="checkbox"]:checked');
        payment != null ? sessionStorage.setItem('coursePaymentMethod', payment.value) : '';

        if (document.querySelector('input[name="payment-method-promo-check"][type="checkbox"]:checked')) {
          this.checkPromo();
        }
      }
    },
    checkPromo: function () {
      let promoCheck = document.querySelector('input[name="payment-method-promo-check"][type="checkbox"]');
      sessionStorage.setItem('coursePaymentMethod', 'promo');

      document.querySelector("#floatingCardBtn").removeEventListener("click", this.triggerModal);
      document.querySelector("#courseBtn").removeEventListener("click", this.triggerModal);
      document.querySelector("#learningTopicBtn").removeEventListener("click", this.triggerModal);

      if (document.querySelector('input[name="checkboxes-payment-method"][type="checkbox"]:checked') != null || document.querySelector('input[name="checkboxes-payment-method"][type="checkbox"]:checked') != undefined) {
        document.querySelector('input[name="checkboxes-payment-method"][type="checkbox"]:checked').checked = false;
      }

      if (promoCheck.check != undefined || promoCheck == 'active' || promoCheck.check != null) {
        sessionStorage.setItem('promo', 'disable');
        this.promo = 'disable';
      }
      else {
        sessionStorage.setItem('promo', 'active');
        this.promo = 'active';

        document.querySelector("#floatingCardBtn").addEventListener("click", this.triggerModal);
        document.querySelector("#courseBtn").addEventListener("click", this.triggerModal);
        document.querySelector("#learningTopicBtn").addEventListener("click", this.triggerModal);

        this.getMercadoPagoURL(this.pricePesos - (this.pricePesos * 0.20).toFixed());
      }
    },
    triggerModal(e) {

      e.preventDefault();

      this.$swal("Serás redirigido al sitio de Mercado Pago",
        `<h3>Recuerda que luego de concretar el pago deberás (toma nota)</h3>
          Enviar un correo a <a href='mailto:hello@abstracta.academy' target='_blank'>hello@abstracta.academy</a> especificando: 
          <ol>
            <li><b>1.</b> Número de operación</li>
            <li><b>2.</b> A nombre de quién se hizo la transacción</li>
            <li><b>3.</b> Qué curso compraste</li>
            <li><b>4.</b> Nombre, apellido, C.I. y mail de la persona que realizará el curso</li>
          </ol>
          Confirmaremos el pago y enviaremos un mensaje de bienvenida a quién realice el curso junto a los accesos para comenzar con la capacitación.`,
        "info");
      document.querySelector(".swal2-confirm").addEventListener("click", () => {
        window.location.replace(sessionStorage.courseMercadoPagoURL);
      });
    },
    check: function (e) {
      sessionStorage.setItem('coursePaymentMethod', e);
      let promoCheck = document.querySelector('input[name="payment-method-promo-check"][type="checkbox"]');
      promoCheck != null ? promoCheck.checked = false : '';
      this.promo = 'disable';
      sessionStorage.setItem('promo', 'disable');

      const learningTopicBtn = document.querySelector("#learningTopicBtn");

      if (e == 'mercadopago') {

        document.querySelector("#floatingCardBtn").addEventListener("click", this.triggerModal);
        document.querySelector("#courseBtn").addEventListener("click", this.triggerModal);

        if (learningTopicBtn) {
          learningTopicBtn.addEventListener("click", this.triggerModal);
        }

        if (this.name == 'Software tester + Clases en vivo') {
          this.getMercadoPagoURL(this.pricePesos - (this.pricePesos * 0.10).toFixed());
        } else {
          this.getMercadoPagoURL(this.pricePesos);
        }
      }
      else {
        document.querySelector("#floatingCardBtn").removeEventListener("click", this.triggerModal);
        document.querySelector("#courseBtn").removeEventListener("click", this.triggerModal);

        if (learningTopicBtn) {
          learningTopicBtn.removeEventListener("click", this.triggerModal);
          learningTopicBtn.href = '/es/concretar-compra';
        }

        document.querySelector("#floatingCardBtn").href = '/es/concretar-compra';
        document.querySelector("#courseBtn").href = '/es/concretar-compra';
      }
    },
    getMercadoPagoURL(price) {
      let preference = {
        back_urls: {
          success: "https://abstracta.academy/es/checkout/success",
          pending: "https://abstracta.academy/es/checkout/pending",
          failure: "https://abstracta.academy/es/checkout/failure",
        },
        items: [
          {
            title: this.name,
            description: this.shortName,
            currency_id: "UYU",
            unit_price: price,
            quantity: 1,
          },
        ],
        notification_url: "https://abstracta.academy/es/",
      };

      fetch("https://api.mercadopago.com/checkout/preferences", {
        method: "POST",
        headers: {
          "Authorization": "Bearer APP_USR-3885192673014781-050413-5e4366a682abd3203b44c093e3674169-1178403161",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(preference),
      })
        .then(response => response.json())
        .then(data => {
          sessionStorage.setItem('courseMercadoPagoURL', data.init_point);
          this.buttonURL = sessionStorage.courseMercadoPagoURL;
        })
        .catch(error => console.log(error));
    }
  },
  watch: {
    async paymentMethod(newVal, oldVal) {
      if (newVal.length == 0) {
        await this.$nextTick();
        this.paymentMethod = oldVal;
      }
      else if (newVal.length == 2) {
        await this.$nextTick();
        this.paymentMethod = newVal.filter(v => (!oldVal.includes(v)));
      }
    },
  },
  mounted() {
    let payment = document.querySelectorAll('input[name="checkboxes-payment-method"][type="checkbox"]:checked');
    sessionStorage.setItem('coursePaymentMethod', payment[0].value);
    let paymentPromo = document.querySelectorAll('input[name="payment-method-promo-check"][type="checkbox"]:checked');
    paymentPromo.checked ? sessionStorage.setItem('promo', 'active') : sessionStorage.setItem('promo', 'disable');
  }
};
