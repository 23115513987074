import { render, staticRenderFns } from "./contacto.vue?vue&type=template&id=95c7b81a&scoped=true"
import script from "./contacto.vue?vue&type=script&lang=js"
export * from "./contacto.vue?vue&type=script&lang=js"
import style0 from "./contacto.vue?vue&type=style&index=0&id=95c7b81a&prod&lang=scss&scoped=true"
import style1 from "./contacto.vue?vue&type=style&index=1&id=95c7b81a&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95c7b81a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBar: require('/builds/marketing/abstracta.academy-website/components/NavBar.vue').default})
