//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCol, BContainer, BRow, BCard, BCardText } from 'bootstrap-vue';

export default {
  name: 'CustomTrainingPage',
  layout: "default",
  head() {
    return {
      title: 'Empresas',
      meta: [
        { hid: 'description', name: 'description', content: '¡Creamos Abstracta Academy porque el mundo necesita más y mejores testers!' },
        { hid: 'keywords', name: 'keywords', content: 'abstracta academy' },
        { hid: 'og:image', property: 'og:image', content: require('~/assets/img/abstracta-academy-meta-data.jpg') },
      ]
    }
  },
  data() {
    return {
      testimonyList: [
        {
          name: "Nicolás Reina",
          position: "QC Analyst Ssr Adv",
          text: `"Abstracta Academy es sin lugar a dudas un lugar donde crecer profesionalmente de forma exponencial. El contenido ha sido desarrollado por profesionales en cada materia, performance, automation, accesibilidad y más, todo en un solo lugar. Creo que es un gran sitio para comenzar y seguir capacitándose en el mundo del testing, altamente recomendable".`,
          photo: require("~/assets/img/testimony-photo-nicolas-reina.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
        {
          name: "Milagros Olivera",
          position: "Software Tester Egresada de ReconverTIte",
          text: `"Como participante de <a href="http://reconvertite.uy" target="_blank">ReconverTIte<a>, con Abstracta Academy realicé mi primera capacitación 100% online y fue realmente una gran experiencia, me sentí siempre acompañada. Los cursos son muy dinámicos y con diferentes contenidos como videos explicativos, desafíos, actividades. ¡Academy colmó mis expectativas!".`,
          photo: require("~/assets/img/testimony-photo-milagros-olivera.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
        {
          name: "Ing. Carinna Bálsamo, MBA",
          position: "Directora de Jóvenes a Programar",
          text: `"Con el apoyo de Abstracta Academy, <span class="text-primary">Jóvenes a Programar (JaP)</span> pudo escalar su capacitación en testing para jóvenes de todo el país logrando así capacitarlos en los fundamentos de esta disciplina que toma cada vez más relevancia en el desarrollo de software. En particular, la base del temario del curso de testing de <span class="text-primary">JaP</span> ha sido definida a partir de contenidos que Abstracta Academy puso a disposición para el programa".`,
          photo: require("~/assets/img/testimony-photo-carina-balsamo.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
        {
          name: "Luis Zambra",
          position: "Test Automation Engineer",
          text: `"Tuve la oportunidad de realizar varios cursos en Abstracta Academy, además participé en la plataforma como instructor, y puedo decir sin dudas que la experiencia ha sido increíble. Es un gran desafío elaborar cursos donde transmitís conocimientos y experiencias para que otros puedan aprovecharlos".`,
          photo: require("~/assets/img/testimony-photo-luis-zambra.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
        {
          name: "Clara Pagani",
          position: "Software Tester Egresada de ReconverTIte",
          text: `"Abstracta Academy fue mi primera experiencia de e-learning, oportunidad que tuve por ser parte de ReconverTIte. Al principio tenía muchas incertidumbres. La flexibilidad horaria y la comodidad de aprender desde tu hogar son muy valiosas, así como también el compartir la experiencia con otros estudiantes a distancia y el tener una instructora con quien intercambiar constantemente". `,
          photo: require("~/assets/img/testimony-photo-clara-pagani.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
      ],
    }
  }
};
