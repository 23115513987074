//
//
//
//
//
//
//
//
//
//
//

let modalTimeout;

export default {
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        ...i18nHead.meta,
      ],
      link: [
        ...i18nHead.link,
      ]
    }
  },
  mounted() {
  },
  watch: {
    $route(to, from) {
      // Reset form on navigation
      this.$refs.newsletterForm.$children[0].resetForm();
    },
  },
}
